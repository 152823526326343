import PropTypes from 'prop-types';
import { get, set, isNil, isArray, isEmpty, compact, omit, toPairs, map } from 'lodash';
import { gql } from '@apollo/client'; // eslint-disable-line
import sha1 from 'hash.js/lib/hash/sha/1';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { EdmundsAPI, externalDataAPI, WidgetStoreApi } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { isClientError } from 'client/utils/http-status';
import { getTypeNameBySlug } from 'site-modules/shared/utils/query-type-mapping';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { getInventoryTypeForPublicationState } from 'site-modules/shared/utils/inventory-utils/get-inventory-type-for-publication-state';
import {
  getTargetingDataFromVehicle,
  getTargetingDataFromInventory,
} from 'site-modules/shared/components/native-ad/utils/get-ad-targeting';
import {
  ZIP_CODE,
  DMA,
  LOAN_PAYMENT,
  LEASE_PAYMENT,
  INITIAL_URL_PATTERN,
  SORT_BY,
  PF_SUBMODEL_ID,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { BUY_ONLINE_DEALERS } from 'site-modules/shared/constants/inventory/buy-online-dealers';
import { getCBPSortSRPFilter } from 'site-modules/shared/utils/car-buying/srp-filter';
import { getSearchResultsQuery } from 'site-modules/shared/utils/inventory/params-conversion';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { PUB_STATES, PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { BEST_DEAL_DESC } from 'site-modules/shared/constants/sort-types';
import { isCarFinderSRP, isCoreSrpMMYS } from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { getQuery } from 'client/utils/location';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { getSearchFilterValues } from 'site-modules/shared/utils/inventory/search-filter';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { ClientConfig } from 'client/configuration';
import { parseContent } from 'client/data/cms/content';
import { getCpoMakeContentPath } from 'site-modules/shared/utils/cpo-helper';
import { getPagePublicationState } from 'site-modules/shared/utils/inventory/srp-utils';
import { VisitorModel } from './visitor';
import { LocationModel } from './location';
import { UrlModel, URL_CONTEXT_PATH } from './url';
import { PageModel } from './page';
import { ExperimentsModel } from './experiments';
import { CmsModel } from './cms';

export const NO_VIN = 'none';
const VEHICLE_VALUES_TO_OMIT = ['displayPrice'];

const FILTER_TYPES = {
  [INVENTORY_TYPES.CPO]: 'CPO,USED',
  [INVENTORY_TYPES.USED]: 'CPO,USED',
  [INVENTORY_TYPES.NEW]: 'NEW',
};

const TED_6747_BOOST = { 'chal-1': 50, 'chal-2': 65, 'chal-3': 80 };

const DealerInfo = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string,
    stateName: PropTypes.string,
  }),
  displayInfo: PropTypes.shape({
    salesOverallRating: PropTypes.number,
    salesReviewCount: PropTypes.number,
  }),
  distance: PropTypes.number,
  name: PropTypes.string,
  productFeatures: PropTypes.shape({
    onlineLeaseEligible: PropTypes.bool,
    directDealer: PropTypes.bool,
  }),
  phoneNumbers: PropTypes.shape({
    basic: PropTypes.shape({
      areaCode: PropTypes.string,
      prefix: PropTypes.string,
      postfix: PropTypes.string,
    }),
    ppTrackable: PropTypes.shape({
      areaCode: PropTypes.string,
      prefix: PropTypes.string,
      postfix: PropTypes.string,
    }),
  }),
  rooftopLogicalName: PropTypes.string,
  franchiseId: PropTypes.string,
  rooftopId: PropTypes.number,
});

const Prices = PropTypes.shape({
  displayPrice: PropTypes.number,
  tmv: PropTypes.number,
  pricePromise: PropTypes.shape({
    guaranteedPrice: PropTypes.number,
    savings: PropTypes.number,
  }),
  loan: PropTypes.shape({
    payment: PropTypes.number,
  }),
  estimateLeasePromise: PropTypes.shape({
    monthlyPayment: PropTypes.number,
  }),
  savings: PropTypes.number,
});

const Colors = PropTypes.shape({
  nameRGB: PropTypes.string,
  r: PropTypes.number,
  b: PropTypes.number,
  genericName: PropTypes.string,
  g: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
});

const Fuel = PropTypes.shape({
  epaCombinedMPG: PropTypes.number,
  epaCityMPG: PropTypes.number,
  epaHighwayMPG: PropTypes.number,
  epaElectricCombinedMPG: PropTypes.number,
  epaElectricCityMPG: PropTypes.number,
  epaElectricHighwayMPG: PropTypes.number,
  batteryCapacityKwh: PropTypes.number,
});

const StyleInfo = PropTypes.shape({
  trim: PropTypes.string,
  year: PropTypes.number,
  model: PropTypes.string,
  style: PropTypes.string,
  styleId: PropTypes.string,
  make: PropTypes.string,
  subModels: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      name: PropTypes.string,
      niceId: PropTypes.string,
    })
  ),
  fuel: Fuel,
});

const VehicleInfo = PropTypes.shape({
  photo: PropTypes.shape({
    defaultPhoto: PropTypes.shape({
      small: PropTypes.shape({
        url: PropTypes.string,
      }),
      largePhotoCount: PropTypes.number,
      large: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  styleInfo: StyleInfo,
  partsInfo: PropTypes.shape({
    driveTrain: PropTypes.string,
    engineType: PropTypes.string,
    transmission: PropTypes.string,
  }),
  mileage: PropTypes.number,
  vehicleColors: PropTypes.shape({
    exterior: Colors,
    interior: Colors,
  }),
});

const CountByMileage = PropTypes.shape({
  count: PropTypes.number,
  cpoCount: PropTypes.number,
  mileageGrid: PropTypes.shape({}), // Empty shape for dynamic keys object
});

const Vin = PropTypes.string;
const Vid = PropTypes.string;
const SellersComments = PropTypes.string;
const StockNumber = PropTypes.string;
const Type = PropTypes.oneOf(['NEW', 'USED', 'CPO']);

const PriceValidation = PropTypes.shape({
  listPriceEstimate: PropTypes.number,
  dealType: PropTypes.string,
  maxGreatPrice: PropTypes.number,
  maxGoodPrice: PropTypes.number,
  maxFairPrice: PropTypes.number,
});

const ThirdPartyInfo = PropTypes.shape({
  priceValidation: PriceValidation,
});

const ComputedDisplayInfo = PropTypes.shape({
  ctas: PropTypes.arrayOf(PropTypes.string),
  showPhoneNumber: PropTypes.bool,
  perksAvailable: PropTypes.bool,
  phoneNumber: PropTypes.string,
  priceStrikethrough: PropTypes.bool,
  transparentPricingCompliance: PropTypes.shape({
    guaranteedPrice: PropTypes.number,
    bestDealPrice: PropTypes.number,
  }),
  priceValidation: PriceValidation,
  negotiationTips: PropTypes.shape({
    sellingSlowly: PropTypes.bool.isRequired,
  }),
});

const ComputedInfo = PropTypes.shape({
  bestDealPrice: PropTypes.number,
  savingsPercent: PropTypes.number,
});

const Incentive = PropTypes.shape({
  amount: PropTypes.number,
  expireDate: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
});

const ConditionPricingValues = PropTypes.shape({
  baseInvoice: PropTypes.number,
  baseMSRP: PropTypes.number,
  deliveryCharges: PropTypes.number,
  estimateTmv: PropTypes.number,
  tmv: PropTypes.number,
  tmvRecommendedRating: PropTypes.number,
  usedPrivateParty: PropTypes.number,
  usedTmvRetail: PropTypes.number,
  usedTradeIn: PropTypes.number,
});

const tmvBands = PropTypes.shape({
  OUTSTANDING: ConditionPricingValues,
  CLEAN: ConditionPricingValues,
  AVERAGE: ConditionPricingValues,
  ROUGH: ConditionPricingValues,
});

export const AppraisalWidgetInfo = PropTypes.shape({
  pagePriceAverage: PropTypes.number,
  pageMileageAverage: PropTypes.number,
  vehicle: PropTypes.shape({
    make: PropTypes.string,
    year: PropTypes.number,
    model: PropTypes.string,
    style: PropTypes.string,
  }),
  tmvBands,
});

const Incentives = PropTypes.shape({
  lease: PropTypes.arrayOf(Incentive),
  purchase: PropTypes.arrayOf(Incentive),
});

const DeliveryOptions = PropTypes.shape({
  deliveryCode: PropTypes.string,
});

/**
 * EX: https://qa-21-www.edmunds.com/gateway/api/inventory/v5/onePerDealer?make=honda&model=accord&year=2017&type=NEW&fields=type,vehicleInfo(photo.defaultPhoto,styleInfo(make,model,year,trim,style),mileage,partsInfo.transmission,vehicleColors(exterior,interior)),prices(displayPrice,loan.payment,savings),thirdPartyInfo(priceValidation.dealType),dealerInfo(name,rooftopLogicalName,partnerCode,franchiseId,rooftopId,productFeatures(directDealer),displayInfo(salesOverallRating,salesReviewCount),address(city,stateName)),sellersComments,vin,vid&zip=90401&radius=25&sortby=sortOrders.tier,_geo&pagenum=1&pagesize=10
 */
const InventoryVin = PropTypes.shape({
  computedInfo: ComputedInfo,
  computedDisplayInfo: ComputedDisplayInfo,
  dealerInfo: DealerInfo.isRequired,
  incentives: Incentives,
  prices: Prices,
  sellersComments: SellersComments,
  stockNumber: StockNumber,
  thirdPartyInfo: ThirdPartyInfo,
  type: Type.isRequired,
  vehicleInfo: VehicleInfo.isRequired,
  vin: Vin.isRequired,
  vid: Vid,
  deliveryOptions: DeliveryOptions,
  derivedGenericFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      optionText: PropTypes.string,
      featureCategory: PropTypes.string,
    })
  ),
  isTED6747Chal: PropTypes.bool,
});

const InventoriesVin = PropTypes.arrayOf(InventoryVin);

const Inventories = PropTypes.shape({
  totalNumber: PropTypes.number,
  totalPages: PropTypes.number,
  results: InventoriesVin,
});

const PriceRange = PropTypes.shape({
  from: PropTypes.number,
  to: PropTypes.number,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      make: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      inventoryCount: PropTypes.number,
    })
  ),
});

const historyInfo = PropTypes.shape({
  listPrice: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      timestamp: PropTypes.number,
    })
  ),
  guaranteedPrice: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      timestamp: PropTypes.number,
    })
  ),
});

const PriceRanges = PropTypes.arrayOf(PriceRange);

const FacetValue = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
  count: PropTypes.number,
  minDisplayPrice: PropTypes.number,
  rgb: PropTypes.string,
  selected: PropTypes.bool,
  type: PropTypes.string,
  niceName: PropTypes.string,
});

const FacetCategoryItem = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(FacetValue),
});

const Facet = PropTypes.shape({
  type: PropTypes.string,
  values: PropTypes.arrayOf(FacetValue),
  groups: PropTypes.objectOf(PropTypes.arrayOf(FacetValue)),
  categories: PropTypes.arrayOf(FacetCategoryItem),
  min: PropTypes.number,
  max: PropTypes.number,
  lowerBound: PropTypes.bool,
  upperBound: PropTypes.bool,
  selected: PropTypes.bool,
});

const Facets = PropTypes.arrayOf(Facet);

const Attributes = PropTypes.shape({
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
      modelLinkCode: PropTypes.string,
    }),
  }),
  dealerInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    rooftopLogicalName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
  }),
  sort: PropTypes.string,
  paymentType: PropTypes.string,
  lowInventoryLeadForm: PropTypes.bool,
});

const SearchResults = PropTypes.shape({
  inventories: Inventories,
  facets: Facets,
  suggestedFacets: Facets,
  attributes: Attributes,
});

const WarrantyItem = PropTypes.shape({
  comments: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.string.isRequired,
  maxMileage: PropTypes.string,
  maxMileageType: PropTypes.string,
  maxYears: PropTypes.string,
  maxYearsType: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  warrantyType: PropTypes.string,
});

const Warranty = PropTypes.arrayOf(WarrantyItem);

export const InventoryEntities = {
  Incentive,
  Incentives,
  InventoryVin,
  InventoriesVin,
  Inventories,
  EstimatedSavings: PropTypes.shape({
    estimatedSavings: PropTypes.number,
    estimatedPrice: PropTypes.number,
  }),
  CountByMileage,
  PriceRanges,
  historyInfo,
  ComputedInfo,
  ComputedDisplayInfo,
  Facets,
  Facet,
  FacetValue,
  FacetCategoryItem,
  DealerInfo,
  VehicleInfo,
  StyleInfo,
  Vin,
  Type,
  SellersComments,
  Prices,
  ThirdPartyInfo,
  SearchResults,
  Attributes,
  PriceValidation,
  Fuel,
  Warranty,
  WarrantyItem,
};

function buildMakeModelInventoryCountPath({ makeName, modelName, pubState = PUB_STATES.NEW }) {
  return `inventoryCount.make.${makeName}.model.${modelName}.pubState.${pubState}`;
}

function buildMMYValidPhotosPath({ makeName, modelName, year }) {
  return `make["${makeName}"].model["${modelName}"].year["${year}"].validPhotos`;
}

function buildVinWarrantyPath(vin) {
  return `vin["${vin}"].vinStyle.style.warranty`;
}

function getCarmax360(stockNum) {
  return `carmax360.stockNum["${stockNum}"]`;
}

const buildSearchResultsPriceIntervalsPath = () => 'searchResultsPriceIntervals';

const buildSearchResultsPriceIntervalsQueryPath = () => 'searchResultsPriceIntervalsQuery';

const getAdTargeting = () => 'adTargeting';

const usePageVehicleForAdsTargetingPath = () => 'usePageVehicleForAdsTargeting';

export const InventoryPaths = {
  buildMakeModelInventoryCountPath,
  buildMMYValidPhotosPath,
  buildVinWarrantyPath,
  buildSearchResultsPriceIntervalsPath,
  buildSearchResultsPriceIntervalsQueryPath,
  getCarmax360,
  getAdTargeting,
  usePageVehicleForAdsTargetingPath,
};

const DEFAULT_ARRAY = [];
const DEFAULT_VINS = {
  totalNumber: 0,
  results: DEFAULT_ARRAY,
};

export const transformInventoryType = inventoryType => {
  if (isArray(inventoryType)) {
    return inventoryType.map(type => type.toUpperCase()).join(',');
  }

  return inventoryType;
};

export const getRadiusByDmaRank = dmaRank => {
  if (dmaRank <= 10) {
    return 25;
  } else if (dmaRank <= 53) {
    return 50;
  }
  return 100;
};

/**
 * A helper which calculates radius based on DMA
 * @param {Object} location
 * @returns {Promise}
 */
export function getSearchArea(location) {
  return Promise.resolve({
    ...location,
    radius: getRadiusByDmaRank(get(location, 'dmaRank')),
  });
}

export const transformDamPhotos = result => {
  result.forEach(photo => {
    Object.assign(photo, { url: photo.src });
  });
  return result;
};

function detectYear(modelYear) {
  const NO_VALUE = '';
  const year = get(modelYear, 'year', NO_VALUE);
  return year === 'agnostic' ? NO_VALUE : year;
}

/**
 * Get search results page API Url
 * @param {object} params
 * @returns {string || null}
 */
export function getSearchResultPageUrl(params) {
  const {
    make,
    model,
    modelYear,
    subModel,
    styleId,
    radius,
    zipCode,
    latitude,
    longitude,
    dma,
    cgf,
    extColors,
    rooftopId,
    displayPrice,
    inventoryType,
    publicationState,
    responseExclusion,
    pageSize = 19,
    pageNum = 1,
    facets = false,
    sortBy = '_geo:ASC,onePerDealer:ASC',
    fields = 'type,computedInfo(bestDealPrice),vehicleInfo(photo.defaultPhoto.largePhotoCount,styleInfo(bodyType,make,model,year,trim,style,subModels,styleId),mileage,partsInfo(engineType,driveTrain,transmission),vehicleColors(exterior,interior)),prices(displayPrice,loan.payment,savings,tmv,guaranteedPrice),thirdPartyInfo(priceValidation.dealType),dealerInfo(name,rooftopLogicalName,partnerCode,franchiseId,rooftopId,productFeatures(directDealer),displayInfo(salesOverallRating,salesReviewCount),address(city,stateName,stateCode)),sellersComments,vin,vid,stockNumber',
  } = params;

  if (!displayPrice && !styleId && (!make || !model)) {
    return null;
  }

  const type = inventoryType || getInventoryTypeForPublicationState(publicationState);
  const year = detectYear(modelYear);
  const queryParams = compact([
    `pagenum=${pageNum}&pagesize=${pageSize}&sortby=${sortBy}`,
    displayPrice && `&prices.displayPrice=${displayPrice}`,
    type && `&type=${type}`,
    rooftopId && `&dealerInfo.rooftopId=${rooftopId}`,
    !rooftopId && `&radius=${radius}&zip=${zipCode}&lat=${latitude}&lon=${longitude}`,
    !rooftopId && dma && `&userDma=${dma}`,
    responseExclusion && `&${responseExclusion}`,
    make && `&make=${make.niceName}`,
    model && `&model=${model.niceName}`,
    subModel && subModel.niceName && `&submodel=${subModel.niceName}`,
    year && `&year=${year}`,
    styleId && `&styleId=${styleId}`,
    extColors && `&vehicleInfo.vehicleColors.exterior.name=${extColors}`,
    cgf && `&vehicleInfo.partsInfo.cgf.name=${cgf}`,
  ]).join('');

  return `/inventory/v5/searchresultspage?${queryParams}&fields=${fields}&facets=${facets}`;
}

export function fetchSuggestedFacets(query, urlPattern) {
  const fetchFacetUrlPatterns = ['make-lease-deals', 'make-lease-deals-city-state'];
  return fetchFacetUrlPatterns.includes(urlPattern)
    ? `${query}&fetchFacets=false`
    : `${query}&fetchSuggestedFacets=true`;
}

/**
 * Adds params to searchResults API.
 * It's temporary solution untill backend starts determine location.
 * @param {String} query
 * @param {Object} urlData
 * @returns {String}
 */
export function formatSearchResultsUrlParams(query, { urlPattern, location }) {
  const urlPatternQuery = `${query}&urlPattern=${urlPattern}`;

  if (location) {
    const locationQueryParams = objectToQueryString({ city: location.city, stateCode: location.stateCode });
    return `${urlPatternQuery}${locationQueryParams ? `&${locationQueryParams}` : ''}`;
  }

  return urlPatternQuery;
}

/**
 * Modifies `/ctavin` response:
 * - for used set priceStrikethrough to false.
 * @param {object} inventory
 * @returns {object}
 */
export async function modifyCTAVINResponse(inventory) {
  const type = get(inventory, 'type', '');
  const isUsedVehicle = isUsed(type);

  if (isUsedVehicle) {
    set(inventory, 'computedDisplayInfo.priceStrikethrough', false);
  }

  let newCtas = get(inventory, 'computedDisplayInfo.ctas');

  if (!newCtas || !newCtas.length) return inventory;

  if (newCtas && newCtas.length) {
    newCtas = newCtas.filter(cta => cta !== CTA_TYPE.ONLINE_LEASE && cta !== CTA_TYPE.DIGITAL_RETAIL);
  }

  set(inventory, 'computedDisplayInfo.ctas', newCtas);

  return inventory;
}

/**
 * Add transparent prices to computedDisplayInfo.
 * @param {object[]} inventories
 */
export function addTransparentPrices(inventories) {
  inventories.forEach(inventory => {
    const bestDealPrice = get(inventory, 'computedInfo.bestDealPrice');
    const guaranteedPrice = get(inventory, 'prices.guaranteedPrice');
    set(inventory, 'computedDisplayInfo.transparentPricingCompliance', { bestDealPrice, guaranteedPrice });
  });
}

/**
 * Get buy online parameters to purchasefunnel API
 * @param {boolean} isBuyOnline
 * @param {object} filters
 */
export function getBuyOnlineDealersParameters(isBuyOnline, filters) {
  if (!isBuyOnline) {
    return null;
  }

  const shouldDefaultSort = !get(filters, SORT_BY, null) && Object.keys(filters).length > 1;

  return {
    inventoryType: ['used', 'cpo'],
    national: true,
    radius: 6000,
    homeDelivery: true,
    seoInfo: 'false',
    parentDealership: BUY_ONLINE_DEALERS,
    ...(shouldDefaultSort && { [SORT_BY]: [BEST_DEAL_DESC] }),
  };
}

async function getActiveExperiments(context) {
  const computed = await context.resolveValue('computed', ExperimentsModel);
  const published = await context.resolveValue('published', ExperimentsModel);
  return { experiments: { computed, published } };
}

function isSHOP1857mfnChallenger1({ experiments }) {
  return (
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state: {
        ...experiments,
      },
      campaignName: 'shop-1857-model-family',
      defaultVal: 'ctrl',
    }) === 'chal1'
  );
}

function isSHOP1857mfnChallenger2({ experiments }) {
  return (
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state: {
        ...experiments,
      },
      campaignName: 'shop-1857-model-family',
      defaultVal: 'ctrl',
    }) === 'chal2'
  );
}

async function isTED6747Eligible({ context, isUsedType }) {
  const experiments = await getActiveExperiments(context);
  return (
    isUsedType &&
    ['chal-1', 'chal-2', 'chal-3'].includes(
      ExperimentUtil.getForcedOrAssignedRecipeName({
        state: {
          ...experiments,
        },
        campaignName: 'TED-6747-CarZing-Leads-v2',
        defaultVal: 'ctrl',
      })
    )
  );
}

async function getTED6747Recipe({ context }) {
  const experiments = await getActiveExperiments(context);
  return ExperimentUtil.getForcedOrAssignedRecipeName({
    state: {
      ...experiments,
    },
    campaignName: 'TED-6747-CarZing-Leads-v2',
    defaultVal: 'ctrl',
  });
}

async function getSHOP2880Recipe({ context }) {
  const experiments = await getActiveExperiments(context);
  return ExperimentUtil.getForcedOrAssignedRecipeName({
    state: {
      ...experiments,
    },
    campaignName: 'shop-2880-new-used-sort',
    defaultVal: 'ctrl',
  });
}

function isSHOP2880Eligible({ shop2880Recipe, filters, isNational, isNewUsedType, isBuyOnline }) {
  return shop2880Recipe !== 'ctrl' && !isNational && isNewUsedType && !filters?.[SORT_BY] && !isBuyOnline;
}

/**
 * Builds an array of active challengers for purchase funnel API
 * @param context - InventoryModel context
 * @param filters - Filters
 * @returns {string[]} - List of active challengers for purchase funnel API. Example: ['blt-1637-data-image']
 */
async function prepareSearchResultsChallengers(context, filters) {
  const challengerResolversMap = {
    'shop-1857-model-family-chal1': isSHOP1857mfnChallenger1,
    'shop-1857-model-family-chal2': isSHOP1857mfnChallenger2,
  };
  const experiments = await getActiveExperiments(context);

  const resolvedChallengers = await Promise.all(
    map(toPairs(challengerResolversMap), async ([challengerName, resolver]) => [
      challengerName,
      await resolver({ context, filters, experiments }),
    ])
  );

  return resolvedChallengers.filter(([, isActive]) => isActive).map(([challengerName]) => challengerName);
}

export const InventoryModel = createModelSegment('inventory', [
  {
    path: 'vin["{vin}"]',
    async resolve({ vin }, context) {
      if (vin === NO_VIN) {
        return {};
      }

      const pageLocation = (await context.resolveValue('location', PageModel)) || {};
      const query = getQuery(pageLocation);
      const vinAPI = `/purchasefunnel/v2/displayvin/`;

      const { dma, zipCode, latitude, longitude, stateCode } = await context.resolveValue(
        'location',
        VisitorModel,
        false
      );
      const radius = get(query, 'radius');
      const isDesktop = await context.resolveValue('isDesktop');
      const isVdpDeliveryCost = await context.resolveValue('shop-3189-graphql-delivery', FeatureFlagModel);

      const url = `${vinAPI}${vin}?lat=${latitude}&lon=${longitude}${dma ? `&dma=${dma}` : ''}&zip=${zipCode}${
        radius ? `&radius=${radius}` : ''
      }${isDesktop ? '&stockPhotoWidth=1280' : '&stockPhotoWidth=815'}${stateCode ? `&stateCode=${stateCode}` : ''}${
        isVdpDeliveryCost ? `&challenger=shop-3063-vdp-delivery-cost` : ''
      }`;
      const inventory = await withMetrics(EdmundsAPI, context).fetchJson(url);
      const isTED6747Chal = await isTED6747Eligible({
        context,
        isUsedType: isUsed(inventory?.type ?? ''),
      });
      set(inventory, 'isTED6747Chal', isTED6747Chal);
      return modifyCTAVINResponse(inventory);
    },
    // manually set path data for cases when data is available, and we don't need to make this additional call
    update(vinData) {
      return modifyCTAVINResponse(vinData);
    },
  },
  /**
   * Get inventory warranty by vin
   * @see buildVinWarrantyPath
   */
  {
    path: 'vin["{vin}"].vinStyle.style.warranty',
    async resolve({ vin }, context) {
      const response = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($vin: String!) {
            inventory(vin: $vin) {
              vinStyle {
                style {
                  warranty {
                    comments
                    endDate
                    id
                    maxMileage
                    maxMileageType
                    maxYears
                    maxYearsType
                    name
                    startDate
                    warrantyType
                  }
                }
              }
            }
          }
        `,
        { vin }
      );
      return get(response, 'inventory.vinStyle.style.warranty');
    },
  },
  {
    path: 'vinWithFallback["{vin}"]',
    async resolve({ vin }, context) {
      return context
        .resolveValue(`vin["${vin}"]`)
        .then(() => ({ $ref: `#/vin/${vin}` }))
        .catch(error => {
          if (isClientError(error.status)) {
            return null;
          }
          throw error;
        });
    },
  },
  {
    path: 'vin["{vin}"].photos',
    resolve(match, context) {
      return context.resolveValue(`vin["${match.vin}"]`).then(vinInfo => {
        const { make, model, year } = vinInfo.vehicleInfo.styleInfo;
        if (make && model && year && !vinInfo.vehicleInfo.photo.defaultPhoto) {
          const url = `/editorial/v2/dam/not-nice-name-photos?make=${make}&model=${model}&year=${year}&pagesize=15&pagenum=1`;
          return withMetrics(EdmundsAPI, context)
            .fetchJson(url)
            .then(transformDamPhotos);
        }
        return [];
      });
    },
  },
  {
    path: 'vin["{vin}"].defaultPhoto',
    resolve(match, context) {
      return context.resolveValue(`vin["${match.vin}"]`).then(vinInfo => {
        if (!vinInfo.vehicleInfo.photo.defaultPhoto) {
          const stockPhotoUrl = get(vinInfo, DATA_PATH.STOCK_PHOTO_URL);
          set(vinInfo, 'vehicleInfo.photo.defaultPhoto.large.url', stockPhotoUrl);
        }
        return vinInfo;
      });
    },
  },
  {
    path: 'vin["{vin}"].full',
    resolve(match, context) {
      if (match && match.vin) {
        const url = `/inventory/v2/vins/${match.vin}?fmt=json&view=full`;
        return withMetrics(EdmundsAPI, context).fetchJson(url);
      }
      return null;
    },
  },
  {
    path: 'make["{make}"].model["{model}"].year["{year}"].stockPhoto',
    resolve(match, context) {
      const { make, model, year } = match;
      const url = `/editorial/v2/dam/not-nice-name-photos?make=${make}&model=${model}&year=${year}&pagesize=1&pagenum=1&shottype=FQ`;
      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  {
    path: 'searchFilter',
    resolve(match, context) {
      return context
        .resolveValue('location', VisitorModel)
        .then(getSearchArea)
        .then(({ zipCode, dma, latitude, longitude, radius, stateCode }) =>
          context.resolveValue('vehicle', PageModel).then(vehicle => ({
            ...omit(vehicle, VEHICLE_VALUES_TO_OMIT),
            radius,
            zipCode,
            dma,
            latitude,
            longitude,
            stateCode,
          }))
        );
    },
    async update(newValue = {}, match, context) {
      const currentSearchFilter = await context.resolveValue('searchFilter');
      const location = await context.resolveValue('location', VisitorModel);
      const { zipCode, dma, latitude, longitude, radius, stateCode } = await getSearchArea(location);

      return {
        ...currentSearchFilter,
        ...newValue,
        radius: get(newValue, 'radius', radius),
        zipCode,
        dma,
        latitude,
        longitude,
        stateCode,
      };
    },
  },
  {
    /**
     * Inventory vins collection retrieval using searchFilter data prefilled. Consider prefill searchFilter via
     * PageModel 'vehicle'. Typically similar things like VehicleStylePreloader are used for that purpose (via
     * redux action directly).
     * Check out client/site-modules/core-page/utils/vehicle-style-preloader.js
     * Returns an empty array in a case of empty make and model in searchFilter since it's impossible to build
     * a query string w/o.
     */
    path: 'vins',
    resolve(match, context) {
      return context.resolveValue('searchFilter').then(searchFilterData => {
        const url = searchFilterData && getSearchResultPageUrl(searchFilterData);

        if (!url) {
          return context.abort();
        }
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(data => {
            const inventories = get(data, 'inventories', DEFAULT_VINS);

            // TODO: Remove when `computedDisplayInfo.transparentPricingCompliance` path will be added on BE
            addTransparentPrices(get(inventories, 'results', []));

            return inventories;
          });
      });
    },
  },
  {
    /**
     * Inventory vins collection retrieval using vin data.
     */
    path: 'similarVins["{vin}"]',
    resolve({ vin }, context) {
      return context.resolveValue(`vinWithFallback["${vin}"]`).then(async vinData => {
        if (isEmpty(vinData)) {
          return null;
        }
        const type = get(vinData, 'type', '');
        const searchFilterValues = {
          ...getSearchFilterValues(vinData, 5),
          inventoryType: FILTER_TYPES[type.toUpperCase()],
          rooftopId: null,
        };
        await context.updateValue('searchFilter', searchFilterValues);
        return context.resolveValue('vins').then(() => ({ $ref: `#/vins` }));
      });
    },
  },
  {
    path: 'vin["{vin}"].dealer.salesTax',
    resolve(match, context) {
      return context.resolveValue(`vin["${match.vin}"]`).then(vin => {
        if (vin && vin.dealerInfo && vin.dealerInfo.address) {
          const dealerZip = vin.dealerInfo.address.zip;

          if (dealerZip) {
            return context.resolveValue(`zips["${dealerZip}"]`, LocationModel).then(location => location.salesTax);
          }
        }

        return 0.0;
      });
    },
  },
  /**
   * Sometimes inventory v5 is missing the COP in the thirdParty section, so we have to get it from here.
   */
  {
    path: 'vin["{vin}"].enhancerPrices',
    resolve: ({ vin }) => WidgetStoreApi.fetchJson(`/enhancer/v1/prices?vin=${vin}`),
  },
  /**
   * http://www.edmunds.com/api/inventory/v5/estimated-savings/401661585
   *
   * @return InventoryEntities.EstimatedSavings
   */
  {
    path: 'estimatedSavings["{styleId}"]',
    resolve: ({ styleId }, context) =>
      withMetrics(EdmundsAPI, context)
        .fetchJson(`/inventory/v5/estimated-savings/${styleId}`)
        .then(json => json.response),
  },
  {
    path: 'mostPopularModelInPriceRange.{typeSlug}',
    async resolve({ typeSlug }, context) {
      const { zipCode, latitude, longitude } = await context.resolveValue('location', VisitorModel);
      const url = `/inventory/v5/most-popular-model-in-price-range/${getTypeNameBySlug(
        typeSlug
      )}?zip=${zipCode}&lat=${latitude}&long=${longitude}&radius=500`;
      const {
        response: { priceRanges },
      } = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return priceRanges;
    },
  },
  {
    path: 'optionPrices["{vin}"]',
    async resolve({ vin }, context) {
      const vinData = await context.resolveValue(`vin["${vin}"]`);
      const styleId = get(vinData, 'vehicleInfo.styleInfo.styleId', '');
      const nonColorOptionIds = get(vinData, 'vehicleInfo.partsInfo.nonColorOptionIds', []);
      if (!styleId || !nonColorOptionIds.length) {
        return {};
      }
      const url = `/vehicle/v3/calculateoptionprice?style=${styleId}&options=${nonColorOptionIds.join(',')}`;
      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  /**
   * Get inventory count by make/model names and pub state
   * Example: https://qa-21-www.edmunds.com/gateway/api/inventory/v5/searchresultspage?type=NEW&vehicleInfo.styleInfo.make=Kia&vehicleInfo.styleInfo.model=Telluride&zip=90404&radius=100&fields=none&pageSize=1&pageNum=1
   * @see buildMakeModelInventoryCountPath
   */
  {
    path: 'inventoryCount.make.{makeName}.model.{modelName}.pubState.{pubState}',
    resolve({ makeName, modelName, pubState }, context) {
      return context.resolveValue('location', VisitorModel).then(({ zipCode, dma, latitude, longitude }) =>
        withMetrics(EdmundsAPI, context)
          .fetchJson(
            `/inventory/v5/searchresultspage?zip=${zipCode}&lat=${latitude}&lon=${longitude}${
              dma ? `&userDma=${dma}` : ''
            }&vehicleInfo.styleInfo.make=${makeName}&vehicleInfo.styleInfo.model=${modelName}&type=${pubState}&radius=100&pageSize=1&pageNum=1&fields=none`
          )
          .then(data => get(data, 'inventories.totalNumber', 0))
          .catch(() => 0)
      );
    },
  },
  /**
   * @example https://www.edmunds.com/gateway/api/purchasefunnel/v1/srp/inventory?zip=10001&type=used&make=honda
   * @example { inventories: {}, facets: [] }
   * @return {Object}
   */
  {
    path: 'searchResults',
    async resolve(match, context) {
      let filters = await context.resolveValue('searchResultsFilter');
      const isBuyOnline = get(filters, 'isBuyOnline', false);
      const ignoreNational = get(filters, 'ignoreNational', false);
      const urlContext = await context.resolveValue(URL_CONTEXT_PATH, UrlModel, false);
      const isNational = !ignoreNational && urlContext && urlContext.attributes && urlContext.attributes.nationalScope;

      // If null/undefined passed, prevents downloading inventory
      // Making a request requires at least an empty object
      if (isNil(filters)) return context.abort();

      const rooftopId = get(filters, 'rooftopId[0]');

      let apiPath;
      const options = {};
      const urlPattern = get(urlContext, 'urlPattern');

      if (rooftopId) {
        apiPath = `/purchasefunnel/v1/srp/rooftop/${rooftopId}`;
        options.timeout = 1000;
      } else {
        apiPath = '/purchasefunnel/v1/srp/inventory';
        options.timeout = 1000;

        // skipCBPButton feature flag for SRP sorting --- start
        const skipCBPButton = await context.resolveValue('skipCBPButton', FeatureFlagModel);
        filters = getCBPSortSRPFilter({ filters, urlContext, skipCBPButton });
        // skipCBPButton feature flag for SRP sorting --- end
      }

      const { zipCode, dma, latitude, longitude, stateCode } = await context.resolveValue(
        'location',
        VisitorModel,
        false // we need prevent update location here, because we do update manually from the page component
      );

      const addLatLongParams = !!latitude && !!longitude;
      const latLongQueryParams = addLatLongParams ? { lat: latitude, lon: longitude } : {};
      const defaultRadiusParam = dma ? { [DMA]: dma } : {};

      const isCarFinder = isCarFinderSRP(filters);
      const locationQueryParams = isCarFinder
        ? {}
        : { [ZIP_CODE]: zipCode, ...defaultRadiusParam, ...latLongQueryParams };
      const pageSize = 21;

      const buyOnlineParams = getBuyOnlineDealersParameters(isBuyOnline, filters);

      const isSrpMMYS = isCoreSrpMMYS(urlPattern);
      const submodelId = get(urlContext, 'vehicle.submodel.id');
      const submodelIdParam = submodelId && isSrpMMYS ? { [PF_SUBMODEL_ID]: submodelId } : {};
      const pagePublicationState = getPagePublicationState(get(filters, 'inventoryType', []));
      const isTED6747Chal = await isTED6747Eligible({
        context,
        isUsedType: [PUB_STATES_LOWERCASE.USED, PUB_STATES_LOWERCASE.NEW_USED].includes(pagePublicationState),
      });
      const ted6747Recipe = await getTED6747Recipe({ context });
      const shop2880Recipe = await getSHOP2880Recipe({ context });
      const isSHOP2880Enabled = isSHOP2880Eligible({
        shop2880Recipe,
        isBuyOnline,
        filters,
        isNational: isNational || isCarFinder,
        isNewUsedType: [PUB_STATES_LOWERCASE.NEW_USED].includes(pagePublicationState),
      });
      const SHOP_2880_SORT_BY_FIELDS = {
        chal1: 'dealerInfo.location:15,vehicleInfo.styleInfo.year:2,sortOrders.tier3:50',
        chal2: 'dealerInfo.location:10,vehicleInfo.styleInfo.year:1,sortOrders.tier3:50',
      };

      let query = getSearchResultsQuery({
        ...locationQueryParams,
        pageSize,
        ...filters,
        ...submodelIdParam,
        national: isNational || isCarFinder || filters?.national,
        ...buyOnlineParams,
        challenger: await prepareSearchResultsChallengers(context, filters),
        stateCode,
        ...(isBuyOnline ? { deliveryCost: true } : {}),
        ...(isTED6747Chal && !isSHOP2880Enabled
          ? { sortByFieldsWithWeights: `sortOrders.tier3:${TED_6747_BOOST[ted6747Recipe]}` }
          : {}),
        ...(isSHOP2880Enabled ? { sortByFieldsWithWeights: SHOP_2880_SORT_BY_FIELDS[shop2880Recipe] } : {}),
      });

      if (urlPattern) {
        // Temporary solution until backend starts determine location
        query = formatSearchResultsUrlParams(query, urlContext);
      }

      const initialUrlPattern = get(filters, INITIAL_URL_PATTERN);

      if (initialUrlPattern) {
        /* todo - if blt-773 wins, we should have `initialUrlPattern` added to the BE so that we
                  are do not need to override urlPattern */
        query = formatSearchResultsUrlParams(query, { urlPattern: initialUrlPattern });
      }

      query = fetchSuggestedFacets(query, urlPattern);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`${apiPath}${query ? `?${query}` : ''}`, options)
        .then(res => {
          const facets = get(res, 'facets', []);

          facets.forEach(facet => {
            const { upperBound, values, type } = facet;
            if (upperBound === false && (type === LOAN_PAYMENT || type === LEASE_PAYMENT)) {
              const index = values.length - 1;
              set(facet, 'showAboveUpperBoundValue', true);
              values.push({ name: `${values[index].name}+` });
            }
          });

          const inventories = get(res, 'inventories.results', []);

          inventories.forEach(inventory => {
            set(inventory, 'isTED6747Chal', isTED6747Chal);
          });

          return {
            ...res,
            hash: sha1()
              .update(query ?? '')
              .digest('hex'),
          };
        });
    },
  },
  /**
   * @example https://www.edmunds.com/gateway/api/purchasefunnel/v1/srp/inventory?dma=866&lat=36.796753&lon=-119.882901&pageSize=1&zip=90404&vin=3VV5B7AX7JM190349&stateCode=CA&fetchSuggestedFacets=false&fetchFacets=false&seoInfo=false
   * @example { inventories: {}, facets: [] }
   * @return {Object}
   */
  {
    path: 'searchResultsVin["{vin}"]',
    async resolve({ vin }, context) {
      const urlContext = await context.resolveValue(URL_CONTEXT_PATH, UrlModel, false);
      const isNational = urlContext?.attributes?.nationalScope;

      const apiPath = '/purchasefunnel/v1/srp/inventory';
      const options = { timeout: 1000 };

      const { zipCode, dma, latitude, longitude, stateCode } = await context.resolveValue(
        'location',
        VisitorModel,
        false // we need prevent update location here, because we do update manually from the page component
      );

      const addLatLongParams = !!latitude && !!longitude;
      const latLongQueryParams = addLatLongParams ? { lat: latitude, lon: longitude } : {};
      const defaultRadiusParam = dma ? { [DMA]: dma } : {};
      const locationQueryParams = { [ZIP_CODE]: zipCode, ...defaultRadiusParam, ...latLongQueryParams };

      const query = getSearchResultsQuery({
        ...locationQueryParams,
        national: isNational,
        pageSize: 1,
        stateCode,
        vin,
        fetchSuggestedFacets: false,
        fetchFacets: false,
        seoInfo: false,
      });

      return withMetrics(EdmundsAPI, context).fetchJson(`${apiPath}${query ? `?${query}` : ''}`, options);
    },
  },
  /**
   * Gets query params from purchase funnel attributes for aggregate API
   * @see buildSearchResultsPriceIntervalsQueryPath
   */
  {
    path: 'searchResultsPriceIntervalsQuery',
    async resolve(match, context) {
      const searchResults = await context.resolveValue('searchResults', InventoryModel, false);

      return searchResults?.attributes?.queryForByIntervalAggregation;
    },
  },
  /**
   * Get price intervals of existing search results
   * @see buildSearchResultsPriceIntervalsPath
   */
  {
    path: 'searchResultsPriceIntervals',
    async resolve(match, context) {
      const query = await context.resolveValue(buildSearchResultsPriceIntervalsQueryPath());

      if (!query) {
        return [];
      }

      const response = await withMetrics(EdmundsAPI, context).fetchJson(`/inventory/v5/aggregate?${query}`, context);

      return response?.buckets;
    },
  },
  /**
   * This filter is used to collect selected facets on SRP pages.
   */
  {
    path: 'searchResultsFilter',
  },
  /**
   * This filter is used to collect data with different params. disablePP - to skip Price Promise.
   */
  {
    path: 'vinFilter',
  },
  /**
   * This filter is used for radius on build price srp page.
   * Separate model field for radius was created so that paths
   * that use radiusFilter will update only on radiusFilter change.
   */
  {
    path: 'radiusFilter',
  },
  {
    path: 'optionDescription["{vin}"]',
    async resolve({ vin }, context) {
      const vinData = await context.resolveValue(`vin["${vin}"]`);
      const options = get(vinData, 'vehicleInfo.partsInfo.options', []);
      const optionsIds = options.map(option => option.id);
      const modelYearId = get(vinData, 'vehicleInfo.styleInfo.modelId', '');

      const url = `/vehicle/v3/equipments?pageSize=50&pageNum=1&modelYearId=${modelYearId}&equipmentClass=OPTION&fields=id,attributeGroups.OPTION_INFO.properties(OPTION_DESCRIPTION,MANUFACTURER_OPTION_NAME)&id=${optionsIds.join(
        ','
      )}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          ({ results }) =>
            results &&
            results.reduce(
              (res, { id, attributeGroups }) => ({
                ...res,
                [id]: {
                  description: get(attributeGroups, 'OPTION_INFO.properties.OPTION_DESCRIPTION'),
                },
              }),
              {}
            )
        );
    },
  },
  /**
   * Add type, pageSize params if needed in future
   * @see buildMMYValidPhotosPath
   */
  {
    path: 'make["{makeName}"].model["{modelName}"].year["{year}"].validPhotos',
    resolve({ makeName, modelName, year }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/inventory/v5/searchresultspage?vehicleInfo.styleInfo.make=${makeName}&vehicleInfo.styleInfo.model=${modelName}&vehicleInfo.styleInfo.year=${year}&type=USED,CPO&prices.displayPrice=1-*&searchable=true&vehicleInfo.photo.validPhotoIndices=1-*&fields=vehicleInfo.photo.defaultPhoto.large&pagesize=8&pagenum=1&facets=false`
        )
        .then(response =>
          get(response, 'inventories.results', []).map(vinData =>
            get(vinData, 'vehicleInfo.photo.defaultPhoto.large.url')
          )
        )
        .catch(() => []);
    },
  },
  /**
   * @see getCarmax360
   */
  {
    path: 'carmax360.stockNum["{stockNum}"]',
    async resolve({ stockNum }) {
      try {
        const api = externalDataAPI(ClientConfig.get('carmaxImgUrl'));
        const url = `/api/subject/${stockNum}`;
        const carmaxResponse = await api.fetchJson(url);
        return carmaxResponse.items;
      } catch {
        return null;
      }
    },
  },
  /**
   * @see usePageVehicleForAdsTargetingPath
   */
  {
    path: 'usePageVehicleForAdsTargeting',
  },
  /**
   * @see getAdTargeting
   */
  {
    path: 'adTargeting',
    async resolve(_, context) {
      const [selectedFilters, usePageVehicleForAdsTargeting] = await Promise.all([
        context.resolveValue('searchResultsFilter', InventoryModel, false),
        context.resolveValue('usePageVehicleForAdsTargeting', InventoryModel),
      ]);

      // If null/undefined passed, prevents downloading inventory
      // Making a request requires at least an empty object
      if (isNil(selectedFilters)) return context.abort();

      if (usePageVehicleForAdsTargeting) {
        const vehicle = await context.resolveValue('vehicle', PageModel);

        if (!vehicle) {
          return context.abort();
        }

        return getTargetingDataFromVehicle({ selectedFilters, vehicle });
      }

      const inventoryData = await context.resolveValue('searchResults', InventoryModel);

      if (!inventoryData) {
        return context.abort();
      }

      return getTargetingDataFromInventory({ inventoryData, selectedFilters });
    },
  },
  {
    path: 'cpoData["{make}"]',
    async resolve({ make }, context) {
      const content = await context.resolveValue(`content["${getCpoMakeContentPath(make)}"]`, CmsModel);
      const certifiedPreOwned = parseContent(content);

      return {
        hasCpoMessage: !isEmpty(certifiedPreOwned.child('cpo-message').getAllMetadata()),
        oemLogo: certifiedPreOwned.metadata('oemLogo').value(),
      };
    },
  },
]);
