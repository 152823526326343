// Note that if an ad unit is named anything but these, it will default to a 1x1.

export const sizes = {
  companion: [[1, 1]],
  mcompanion: [[1, 1]],
  floodlight: [[1, 1]],
  leaderc: [[1, 1], [728, 90]],
  spot: [[1, 1], [300, 90], [300, 180], 'fluid'],
  mrect: [[1, 1], [300, 250]],
  mmrect: [[1, 1], [300, 90], [300, 180], [300, 250]],
  mab: [[1, 1], [300, 50], [320, 50]],
  mbanner: [[1, 1], [300, 50], [320, 50]],
  billboard: [[1, 1], [1152, 516], [970, 250]],
  mbillboard: [[1, 1], [320, 516], [320, 250]],
  mspot: [[1, 1], [300, 90], [300, 180], 'fluid'],
  easlisting: [[1, 1], [2, 1], 'fluid'],
  easlisting_chal: [[2, 1]],
  deeplink: [[1, 1], [320, 52]],
  mdeeplink: [[1, 1], [320, 52]],
  nav: [[1, 1], [342, 56]],
  snav: [[1, 1], [277, 55]],
  sponvidnative: [[1152, 516], 'fluid'],
  edhighimpact: [[1, 1], [658, 288], [610, 343]],
  medhighimpact: [[358, 221], [1, 1]],
  default: [[1, 1]],
  hero: [[1, 1]],
  mhero: [[1, 1]],
  native: [[1, 1], [666, 76], [480, 76], [234, 350], [254, 340], [272, 284], 'fluid'],
  mnative: [[1, 1], [277, 120], [320, 61], [234, 350], [254, 340], [272, 284], 'fluid'],
  nativefedtest: [
    [1, 1],
    [277, 120],
    [320, 61],
    [666, 76],
    [480, 76],
    [300, 180],
    [234, 350],
    [254, 340],
    [272, 284],
    'fluid',
  ],
  sponsoredvideo: [[1, 1], [300, 250], 'fluid'],
  msponsoredvideo: [[1, 1], [300, 250], 'fluid'],
  navblock: [[1, 1], [342, 56]],
  mnavblock: [[1, 1], [342, 56]],
  featuredspot: [[1, 1]],
  mfeaturedspot: [[1, 1]],
  cunit: [[1, 1], [646, 290], [646, 66], [728, 90], [728, 315]],
  pubpixel: [[1, 1]],
  cpopanel: [[1, 1]],
  mcpopanel: [[1, 1]],
  compare: [[1, 1]],
  mcompare: [[1, 1]],
  hproadrunner: [[1, 1]],
  mhproadrunner: [[1, 1]],
  buyguideicon: [[1, 1]],
  mbuyguideicon: [[1, 1]],
};

export function getSizes(unit) {
  return sizes[unit] || sizes.default;
}

export const newSizes = Object.assign({}, sizes, {
  mspot: [[1, 1], [300, 90], 'fluid'],
  native: [[1, 1], 'fluid'],
  mnative: [[1, 1], 'fluid'],
  nativefedtest: [[1, 1], 'fluid'],
  default: [[1, 1]],
});

export function getNewSizes(unit) {
  return newSizes[unit] || newSizes.default;
}
