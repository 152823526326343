import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { xor } from 'lodash';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

function getDefaultRecipe(ownProps) {
  const recipes = ownProps.children;
  const defaultRecipe = recipes.find(recipe => recipe && recipe.props.isDefault);
  if (defaultRecipe) {
    return defaultRecipe;
  }
  return recipes.find(recipe => {
    const name = recipe.props.name.toLowerCase();
    return name.includes('ctrl') || name.includes('control');
  });
}

function markRecipeUsed(campaignName, usedRecipeName, isPageExperiment, forcedRecipeName) {
  if (ExperimentUtil.isInitialized()) {
    /**
     * purpuse of the if is to avoid unit tests having to initialize ExpEngHandler
     * otherwise all tests with a mount would need to if anywhere in the subtree
     * there was an <Experiment />, even if they were not interested in markRecipeUsed,
     * which is majority of the cases
     */
    ExperimentUtil.markRecipeUsed({
      campaignName,
      usedRecipeName,
      isPageExperiment,
      forcedRecipeName,
    });
  }
}

/**
 * Component that allows define multivariant experiment within React components.
 */
class ExperimentContainer extends Component {
  static propTypes = {
    assignedRecipeName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    forcedRecipeName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    isValid: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    pageExperiment: PropTypes.bool.isRequired,
    showDefault: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    validationErrors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
  };
  static defaultProps = {
    validationErrors: null,
  };

  componentDidMount() {
    markRecipeUsed(this.props.name, this.renderedRecipeName, this.props.pageExperiment, this.props.forcedRecipeName);
  }

  componentDidUpdate() {
    markRecipeUsed(this.props.name, this.renderedRecipeName, this.props.pageExperiment, this.props.forcedRecipeName);
  }

  /**
   * returns the Recipe from jsx matching recipeName, or undefined if not found
   * @param recipeName i.e. from wtf API assignment
   * @returns {React.DetailedReactHTMLElement<*, HTMLElement> | *}
   */
  getRecipe(recipeName) {
    const recipes = React.Children.toArray(this.props.children);
    return recipes.find(recipe => recipe && recipe.props.name === recipeName);
  }

  renderErrorMessage(validationErrors) {
    const { name } = this.props;
    console.error(validationErrors.join('\n')); // eslint-disable-line no-console
    return (
      <div style={{ color: 'red' }}>
        <h2>
          Validation of AB-testing experiment <i>{name}</i> has failed:
        </h2>
        <ul>
          {validationErrors.map(validationError => (
            <li key={validationError.replace(' ', '-')}>${validationError}</li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { isValid, validationErrors, showErrors, showDefault, assignedRecipeName, forcedRecipeName } = this.props;

    if (forcedRecipeName) {
      this.renderedRecipeName = forcedRecipeName;
      return <Fragment>{this.getRecipe(forcedRecipeName)}</Fragment>;
    }

    const defaultRecipe = getDefaultRecipe(this.props);

    if (!isValid) {
      if (showErrors) {
        return this.renderErrorMessage(validationErrors);
      }
      if (showDefault && defaultRecipe) {
        return <Fragment>{defaultRecipe}</Fragment>;
      }
      return false;
    }

    const assignedRecipe = this.getRecipe(assignedRecipeName);
    if (!assignedRecipe) {
      return showDefault && defaultRecipe ? <Fragment>{defaultRecipe}</Fragment> : false;
    }
    this.renderedRecipeName = assignedRecipeName;
    return <Fragment>{assignedRecipe}</Fragment>;
  }
}

/**
 * validates recipes
 * @param state
 * @param ownProps
 * @returns {{isValid: *, validationErrors: *}}
 */
function areRecipesValid(state, ownProps) {
  const { showDefault } = ownProps;
  const recipes = ownProps.children;
  const campaignName = ownProps.name;

  let isValid = true;
  const validationErrors = [];

  const defaultRecipe = getDefaultRecipe(ownProps);
  if (showDefault && !defaultRecipe) {
    isValid = false;
    validationErrors.push('showDefault is true but could not find a recipe matching isDefault:true or name:"ctrl"');
  }

  const recipeNamesFromJsx = {};
  recipes.forEach(recipe => {
    const name = recipe.props.name;
    recipeNamesFromJsx[name] = recipeNamesFromJsx[name] ? recipeNamesFromJsx[name] + 1 : 1;
  });

  Object.keys(recipeNamesFromJsx).forEach(name => {
    if (recipeNamesFromJsx[name] > 1) {
      isValid = false;
      validationErrors.push(`Found duplicate for '${name}' recipe name.`);
    }
  });

  const recipeNamesFromState = ExperimentUtil.getRecipeNames({ state, campaignName });
  const recipeNamesFromJsxArray = Object.keys(recipeNamesFromJsx);
  const diff = recipeNamesFromState.length && xor(recipeNamesFromState, recipeNamesFromJsxArray);
  if (diff && diff.length) {
    isValid = false;
    validationErrors.push(
      `Names of the recipe children "${recipeNamesFromJsxArray}" are not the same as the published recipe names "${recipeNamesFromState}".`
    );
  }

  return { isValid, validationErrors: validationErrors.length ? validationErrors : null };
}

/**
 * Maps app redux state to the Experiment component properties.
 * Defines if the target feature is enabled or not.
 *
 * @param  {Object} state    App redux state.
 * @param  {Object} ownProps Own component properties.
 * @return {Object}          Mapped properties.
 */
export const mapStateToProps = (state, ownProps) => {
  const featureFlags = state.featureFlags;
  const showErrors = (featureFlags && featureFlags.wtfShowErrors) || false;
  const campaignName = ownProps.name;
  const assignedRecipeName = ExperimentUtil.getAssignedRecipeName({
    state,
    campaignName,
    isPageExperiment: ownProps.pageExperiment,
  });
  const forcedRecipeName = ExperimentUtil.getForcedRecipeName({
    state,
    campaignName,
  });

  const { isValid, validationErrors } = areRecipesValid(state, ownProps);

  return {
    assignedRecipeName,
    forcedRecipeName,
    isValid,
    showErrors,
    validationErrors: !isValid && showErrors ? validationErrors : null, // avoid re-renders in prod
  };
};

/**
 * Experiment component can be used to define multivariant experiment with React components.
 * If the experiment is disabled, it renders only child Recipe, which has `isDefault=true` attribute.
 * If the experiment is enabled, it executes PlanOut Experiment for the defined recipes.
 * Connects underlying ExperimentContainer component to app Redux state.
 *
 * more @ https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/wikis/wtf
 *
 * @example
 * <Experiment name="test-venom-1", isDefault={true}>
 *   <Recipe name='ctrl' isDefault={true}>
 *     DISPLAYED COMPONENT 1
 *   </Recipe>
 *   <Recipe name='chal'>
 *     DISPLAYED COMPONENT 2
 *   </Recipe>
 * </Experiment>
 *
 * @type {ReactComponent}
 */
export const Experiment = connect(mapStateToProps)(ExperimentContainer);

/**
 * Experiment component prop types.
 *
 * @type {Object}
 */
Experiment.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  showDefault: PropTypes.bool,
  pageExperiment: PropTypes.bool,
};

Experiment.defaultProps = {
  showDefault: false,
  pageExperiment: false,
};
